import { Container } from "react-bootstrap";

const LatpayCopyright = () => {
    return ( 
        <div className="Latpay-copyright p-3">
            <Container>

                <div>
                    <span>All Content Copyright © 2022 Latpay  
                        | <a href="https://latpay.com/privacy-policy"  rel="noopener noreferrer" target="_blank" >Privacy Policy</a> 
                        | <a href="https://latpay.com/terms-condition/"  rel="noopener noreferrer" target="_blank" >Terms &amp; Conditions</a> 
                        | <a href="https://www.latpay.com/wp-content/uploads/2021/11/financial_services_guide.pdf"  rel="noopener noreferrer" target="_blank" >Financial Services Guide</a></span>
                </div>
                <div>
                    <span>Lateral Payment Solutions Pty Ltd (Latpay) ABN 12 610 150 064 is the holder of an Australian Financial Services Licence (AFSL) No. 521901.</span>
                </div>

            </Container>
        </div> 
    );
}
 
export default LatpayCopyright;