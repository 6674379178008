import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
/*
const firebaseConfig = {
  apiKey: "AIzaSyA23Yv20a1rS1eb4ygmf_BQsOgoGBCQrc4",
  authDomain: "latpay-crm.firebaseapp.com",
  projectId: "latpay-crm",
  storageBucket: "latpay-crm.appspot.com",
  messagingSenderId: "1008282216613",
  appId: "1:1008282216613:web:30fa512e11c906df23c54c",
  //keyPair: "BNBI8sgrCpaQ2cDg_PET7bvpD49UEFA3tgEK0tgIlquvlqDAao5SFM0gSMUz37e-f-aNWXDFkbaZKZ1GndjobpY",
};
*/

const firebaseConfig = {
  apiKey: "AIzaSyCI14jMteXxfNqNV8MPXhQfv-AQDUwnaKA",
  authDomain: "latpay-dev.firebaseapp.com",
  projectId: "latpay-dev",
  storageBucket: "latpay-dev.appspot.com",
  messagingSenderId: "929180282501",
  appId: "1:929180282501:web:b37e27829835ee657843ec",
  //keyPair: "BNBI8sgrCpaQ2cDg_PET7bvpD49UEFA3tgEK0tgIlquvlqDAao5SFM0gSMUz37e-f-aNWXDFkbaZKZ1GndjobpY",
};


const app = initializeApp(firebaseConfig);
const fb = getFirestore(app);
const auth = getAuth(app);
const messaging = getMessaging(app);

const getPushToken = async (setTokenFound) => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: firebaseConfig.keyPair });
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
}

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export {fb, auth, messaging, getPushToken, onMessageListener}